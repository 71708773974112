<template>
  <div class="auth-window">
    <div class="auth-window__form-section">
      <header class="auth-window__form-header auth-window__form-header_registration">
        <h3 class="auth-window__form-title">Registration</h3>
      </header>

      <!-- <p>{{ referrer }}</p> -->

      <form
        v-if="!registerSuccess"
        @submit.prevent="submitHandler"
        class="auth-window__form auth-window__form_register"
      >
        <div
          class="auth-window__form-input"
          :class="{
            'input_invalid' : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email),
            'input_valid' : !$v.email.$invalid 
          }"
        >
          <input type="text" v-model.trim="email" placeholder="Email" />

          <small
            class="helper-text invalid"
            v-if="$v.email.$dirty && !$v.email.required"
          >This field must not be empty.</small>
          <small
            class="helper-text invalid"
            v-else-if="$v.email.$dirty && !$v.email.email"
          >Invalid Email.</small>

          <small
            class="helper-text warning"
            v-else-if="email"
          >We will send you e-mail to verify your account.</small>
        </div>

        <div
          class="auth-window__form-input"
          :class="{
            'input_invalid' : ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength), 
            'input_valid' : !$v.password.$invalid
          }"
        >
          <input type="password" v-model.trim="$v.password.$model" placeholder="Password" />

          <small
            class="helper-text invalid"
            v-if="$v.password.$dirty && !$v.password.required"
          >This field must not be empty.</small>
          <small
            class="helper-text invalid"
            v-else-if="$v.password.$dirty && !$v.password.minLength"
          >The field must be at least {{ $v.password.$params.minLength.min }} characters {{ password.length }}.</small>
        </div>

        <div
          class="auth-window__form-input"
          :class="{
            'input_invalid' : ($v.confirm_password.$dirty && !$v.confirm_password.required) || ($v.confirm_password.$dirty && !$v.confirm_password.sameAsPassword), 
            'input_valid' : !$v.confirm_password.$invalid
          }"
        >
          <input
            type="password"
            v-model.trim="$v.confirm_password.$model"
            placeholder="Confirm password"
          />

          <small
            class="helper-text invalid"
            v-if="$v.confirm_password.$dirty && !$v.confirm_password.required"
          >This field must not be empty.</small>
          <small
            class="helper-text invalid"
            v-else-if="$v.confirm_password.$dirty && !$v.confirm_password.sameAsPassword"
          >This field does not match the field password.</small>
        </div>

        <div class="auth-window__agree-row">
          <div class="auth-window__agree-check">
            <b-checkbox class="check-custom" required v-model="subscribe" size="is-medium">&nbsp;</b-checkbox>
            <p class="auth-window__agree-link">
              Yes! Subscribe to News Updates, and Offers
              <br />
              <span
                class="simple-link simple-link_underline"
                @click="isPrivacyModalActive = true"
              >Privacy Policy</span>
            </p>
          </div>

          <div class="auth-window__agree-check">
            <b-checkbox class="check-custom" required v-model="agree" size="is-medium">&nbsp;</b-checkbox>
            <p class="auth-window__agree-link">
              Yes, I agree to Terms of Service
              <br />
              <span
                class="simple-link simple-link_underline"
                @click="isTermsModalActive = true"
              >Terms of Service</span>
            </p>
          </div>
        </div>

        <div class="auth-window__action-row">
          <vue-recaptcha
            class="captha captha_register"
            ref="recaptcha"
            :sitekey="sitekey"
            @verify="onVerify"
            @expired="onExpired"
          />

          <button class="action-auth-btn action-auth-btn_registration">Registration</button>

          <span class="auth-window__change-action">
            Do you have an account?
            <router-link class="simple-link simple-link_underline" to="/login">Login</router-link>
          </span>
        </div>
      </form>

      <div
        v-else
        class="auth-window__form auth-window__form_register auth-window__form_register-success"
      >
        <h1>The activation email was sent. Please confirm it.</h1>

        <div class="auth-window__action-row">
          <router-link class="action-auth-btn action-auth-btn_registration" to="/login">Ok</router-link>
        </div>
      </div>
    </div>

    <!-- <div class="auth-window__slider">Image for Registration here</div> -->

    <b-modal :active.sync="isPrivacyModalActive" :width="515" scroll="keep">
      <div class="auth-window__modal auth-window__modal_privacy">
        <h2 class="auth-window__modal-title">Privacy Policy</h2>
        <div v-bar>
          <div class="auth-window__modal-content">
            <p>This is an Agreement between SMSplaza.io and the Customer (a.k.a. "you") for the provisioning of Communications Services ("Service"). To sign up for SMSplaza.io's Service, you must read, understand, and agree to the terms and conditions of this Agreement. If you are not of legal age, or if you have not read this Agreement in full, or if you do not agree with anything in this Agreement, you cannot subscribe to SMSplaza.io's Service. If you have already done so, you have to cancel the Service immediately.</p>

            <h5>1. Term of Agreement</h5>

            <p>This Agreement goes into effect on the day you create personal account to SMSplaza.io Service. It remains in effect until you use any Service provided by SMSplaza.io either you or SMSplaza.io terminates the Agreement.The Customer gets access to Services through the registration personal account. To enter the personal account requires a login and password.</p>

            <h5>2. Payment</h5>

            <p>The Customer can order Services after TopUp account balance. Services are working on a prepaid basis and will run until exhaustion prepayment. Tariffs for Services can be changed at any time. The new tariffs come into force at the time of the first use of the Service after the publication of new tariffs on the site. We recommend checking the current rates before using the service. If you do not agree with new tariffs, please refrain from using the services. Payment for Services is carried out with the use of different payment systems from personal account. Customer may make payment by any of the methods of payment.</p>

            <h5>3. Allowed Personal Use</h5>

            <p>Service is providing to you entirely and only for your personal use. The only limitation - it is prohibited to use the service for any illegal purposes.</p>

            <h5>4. Compliance with Laws</h5>

            <p>You agree that you will not use Service for any purposes whatsoever that are illegal or unlawful or intended to avoid any payments lawfully due to SMSplaza.io or any governmental agencies. You agree to use Service in compliance with the relevant laws and regulations: The Laws of the location where you use the service and International Laws. That includes paying applicable taxes and usage fees, paying for SMSplaza.io's Service with valid credit cards, and not conducting any illegal activities over or with the use of the phone provided by SMSplaza.io's Service. You agree to honor the Copyrights and Trademarks of all parties involved in the provision of SMSplaza.io's Service. You further agree that SMSplaza.io can at its sole discretion discontinue your Service and/or report your activities to the appropriate law-enforcement agencies if SMSplaza.io determines that your use of Service does not adhere to laws and regulations. If any third party makes SMSplaza.io in connection with the claim: your violation of these Terms; your breach of any applicable legislation or regulations; your violation of the rights of any third party; In such case the Customer undertakes to protect the SMSplaza.io from liability and reimburse all costs and losses, to pay any claims for compensation and other expenses (including reasonable legal costs and reasonable) related to such claim.</p>

            <h5>5. Agreement Termination</h5>

            <p>SMSplaza.io may terminate the provision of services to Customer without notice if the Customer carries out actions that violate the rules and regulations of this agreement and in the case where the customer commits acts that violate applicable law.</p>

            <h5>6. Limits of Liability</h5>

            <p>SMSplaza.io is not liable in the following cases: in the case of indirect damage in the event of accidental damage or consequential damage to then, because of interrupted communications, lost data, lost profits, or in the case of economic losses, which have arisen in connection with the this Agreement. In particular, the company is not liable for any whatsoever to delay any such problems. SMSplaza.io is not responsible for the actions and methods of use of services by the Customer.</p>

            <h5>7. Terms of Money Back Guarantee</h5>

            <p>SMSplaza.io offers a guarantee that will refund the balance of personal account of certain methods defined in personal account. You agree that The Money Back Guarantee Will Not Apply if you spent balance for Services. Furthermore, SMSplaza.io will not refund any other charges that you may have accrued while using the service, regardless of the reasons for accruing those charges. SMSplaza.io reserves the right to terminate or revoke the Money-Back Guarantee at any time, without prior notice.</p>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="isTermsModalActive" :width="515" scroll="keep">
      <div class="auth-window__modal auth-window__modal_terms">
        <h2 class="auth-window__modal-title">Terms of Service</h2>
        <div v-bar>
          <div class="auth-window__modal-content auth-window__modal-content_terms">
            <p>such problems. SMSplaza.io is not responsible for the actions and methods of use of services by the Customer.</p>

            <h5>7. Terms of Money Back Guarantee</h5>

            <p>SMSplaza.io offers a guarantee that will refund the balance of personal account of certain methods defined in personal account. You agree that The Money Back Guarantee Will Not Apply if you spent balance for Services. Furthermore, SMSplaza.io will not refund any other charges that you may have accrued while using the service, regardless of the reasons for accruing those charges. SMSplaza.io reserves the right to terminate or revoke the Money-Back Guarantee at any time, without prior notice. such problems. SMSplaza.io is not responsible for the actions and methods of use of services by the Customer.</p>

            <h5>7. Terms of Money Back Guarantee</h5>

            <p>SMSplaza.io offers a guarantee that will refund the balance of personal account of certain methods defined in personal account. You agree that The Money Back Guarantee Will Not Apply if you spent balance for Services. Furthermore, SMSplaza.io will not refund any other charges that you may have accrued while using the service, regardless of the reasons for accruing those charges. SMSplaza.io reserves the right to terminate or revoke the Money-Back Guarantee at any time, without prior notice.</p>
            <!-- <div class="auth-window__modal-btn-container">
                <button class="auth-window__modal-btn auth-window__modal-btn_cancel" @click="isTermsModalActive = !isTermsModalActive">Cancel</button>
                <button class="auth-window__modal-btn auth-window__modal-btn_ok" @click="isTermsModalActive = !isTermsModalActive">Ok</button>
            </div>-->
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { MamaherService } from "@/services/manager.service";

import { email, required, sameAs, minLength } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: { VueRecaptcha },
  data: () => ({
    email: "",
    password: "",
    confirm_password: "",
    subscribe: true,
    agree: true,
    recaptcha: "",
    sitekey: "6LeAyLYUAAAAANWXmSpKGVpGRb1TNOW09mUmfpLR",
    registerSuccess: false,

    isPrivacyModalActive: false,
    isTermsModalActive: false
  }),
  validations: {
    email: { email, required },
    password: { required, minLength: minLength(8) },
    confirm_password: { required, sameAsPassword: sameAs("password") },
    subscribe: { checked: v => v },
    agree: { checked: v => v }
  },

  computed: {
    ...mapState("auth", ["registrationError"])

    // referrer() {
    //   return document.referrer || "Directly";
    // }
  },

  mounted() {
    let referral = this.$route.query.referral;

    console.log("referral", referral);

    if (this.$route.query.referer) {
      this.$cookies.set("referer", this.$route.query.referer);
    } else if (referral) {
      localStorage.setItem("REFERRAL_KEY", referral);

      MamaherService.visitReferral(referral);
      // this.$cookies.set("referral", this.$route.query.referral);
    }
  },

  methods: {
    ...mapMutations("auth", ["clearRegError"]),

    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      const user = {
        email: this.email,
        password1: this.password,
        password2: this.confirm_password,
        subscribe_for_mailing: this.subscribe,
        recaptcha: this.recaptcha,
        source_url: this.$cookies.get("referer") || "Directly"
      };

      await this.$store.dispatch("auth/register", user);

      if (!this.registrationError) {
        this.registerSuccess = true;
      }

      if (this.registrationError) {
        this.errorHandling();
        this.clearRegError();
        this.clearForm();
      }
    },
    errorHandling() {
      let errorObj = this.registrationError;

      for (let key in errorObj) {
        for (let i = 0; i < errorObj[key].length; i++) {
          this.$toasted.show(errorObj[key][i], {
            theme: "toasted-primary",
            position: "top-right",
            duration: 10000,
            className: "danger-notification"
          });
        }
      }
    },
    clearForm() {
      this.resetRecaptcha();
    },
    onSubmit: function() {
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function(response) {
      this.setTokenCaptcha(response);
    },
    onExpired: function() {
      this.setTokenCaptcha("");
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.setTokenCaptcha("");
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    setTokenCaptcha(recaptchaToken) {
      this.recaptcha = recaptchaToken;
    }
  }
};
</script>


