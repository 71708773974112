<template>
  <div class="auth-window auth-window_reset">
    <div class="auth-window__form-section">
      <header class="auth-window__form-header">
        <h3 class="auth-window__form-title">Unsubscribe form</h3>
      </header>

      <form @submit.prevent="submitHandler" class="auth-window__form auth-window__form_login">
        <div class="auth-window__action-row auth-window__action-row_login">
          <button class="action-auth-btn action-auth-btn_login">Unsubscribe</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ApiService } from "@/services/api.service";
import { BASE_URL } from "@/utils/constants";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapState("auth", ["resetErrorCode"])
  },
  methods: {
    async submitHandler() {
      const requestData = {
        method: "post",
        url: `${BASE_URL}api/users/unsubscribe/`,
        data: {
          token: this.$route.params.code
        }
      };

      try {
        const response = await ApiService.customRequest(requestData);

        this.$router.push("/login");
      } catch (error) {
        console.log(error);
        console.log(error.response.data);

        this.$router.push("/login");
      }
    }
  }
};
</script>


